import Loader from '@/components/atoms/Loader';
import UserProfileOverview from '@/components/organisms/UserProfileOverview';
import { employeeUserRoutes } from '@/constants/routes/app';
import useToast from '@/hooks/useToast';
import UserPeerSupportBioModal from '@/modules/user/UserPeerSupportBioModal';
import { uploadProfilePhoto } from '@/services/api/requests/user';
import { useAppStore } from '@/store/useAppStore';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

const UserProfile = () => {
  const toast = useToast();
  const navigate = useNavigate({ from: employeeUserRoutes.userProfile });
  const { user: userData, setUser } = useAppStore();

  const [isBioModalOpen, setIsBioModalOpen] = useState(false);

  const onClickEditContactDetails = () => {
    navigate({ to: '/user/profile/contact' });
  };

  const onClickEditDemographics = () => {
    navigate({ to: '/user/profile/demographics' });
  };

  const uploadPhoto = async (profilePhoto: File) => {
    try {
      const data = await uploadProfilePhoto(profilePhoto);
      setUser(data);
      toast({
        status: 'success',
        title: 'Success',
        description: 'Profile Photo updated successfully!',
        position: 'top-right',
      });
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  };

  if (!userData) return <Loader />;

  return (
    <>
      <UserProfileOverview
        title={'Your Profile'}
        updateProfilePhoto={uploadPhoto}
        userData={{ ...userData.user_metadata, email: userData.email }}
        onClickEditContactDetails={onClickEditContactDetails}
        onClickEditDemographics={onClickEditDemographics}
        onClickEditPeerSupportBio={() => setIsBioModalOpen(true)}
        initialPreview={userData?.user_metadata.profilePicture}
        userRoles={userData.roles}
      />
      <UserPeerSupportBioModal
        isModalOpen={isBioModalOpen}
        setIsModalOpen={setIsBioModalOpen}
        userMetadata={userData.user_metadata}
      />
    </>
  );
};

export const Route = createFileRoute('/_authenticated/_user/_profile/user/profile')({
  component: UserProfile,
});

import React from 'react';

import { Button, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import MenuPlaceholder from './components/MenuPlaceholder';
import ProfileMenu from './components/ProfileMenu';
import { PROFILE_DROPDOWN_WIDTH_ADMIN, PROFILE_DROPDOWN_WIDTH_EMPLOYEE } from '@/constants/dimensions';
import { useAppStore } from '@/store/useAppStore';
import roles from '@/constants/roles';

interface Props {
  isHeaderTransparent: boolean;
}

const ProfileDropdownMenu = ({ isHeaderTransparent }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAppStore();

  const isDropdownWide = user?.roles.includes(roles.orgAdmin) || user?.roles.includes(roles.peerSupport);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={'bottom'}
      closeOnBlur={true}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          minWidth={'140px'}
          minHeight={'50px'}
          bg={'transparent'}
          cursor={'pointer'}
          paddingY={0}
          paddingRight={0}
        >
          <MenuPlaceholder isOpen={isOpen} isHeaderTransparent={isHeaderTransparent} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={isDropdownWide ? PROFILE_DROPDOWN_WIDTH_ADMIN : PROFILE_DROPDOWN_WIDTH_EMPLOYEE}
        border={0}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        <ProfileMenu
          onPressCallback={() => {
            onClose();
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default ProfileDropdownMenu;

import { api, TCustomAxiosResponse } from '..';
import { TRosterUserPeerSupportCertificateInfo } from '@/types/Roster.types';
import {
  TPeerSupportDashboard,
  TPeerSupportOutreachCreation,
  TPeerSupportProfileInfo,
} from '@/types/PeerSupport.types';
import { TUser } from '@/types/User.types';

const root = 'v1/peersupport';

const endpoints = {
  peerSupport: `${root}`,
  peerSupportProfile: `${root}/profile`,
  peerSupportDashboard: `${root}/dashboard`,
  peerSupportOutreach: `${root}/outreach`,
};

export const updateNewPeerSupportProfilePut = async (peerSupportInfo: TPeerSupportProfileInfo) => {
  const { data } = await api.put<TCustomAxiosResponse<TUser>>(endpoints.peerSupportProfile, {
    ...peerSupportInfo,
  });
  const updatedPeerSupport = data.data;

  return updatedPeerSupport;
};

export const upgradeMemberToPeerSupportPost = async (
  organizationId: string,
  userId: string,
  peerSupportUpgradeInfo: TRosterUserPeerSupportCertificateInfo,
) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(
    `v1/organization/${organizationId}/users/${userId}/upgrade-to-peer-support`,
    {
      certificateId: peerSupportUpgradeInfo.certificateId,
      validationStartDate: peerSupportUpgradeInfo.dateOfIssue,
      validationEndDate: peerSupportUpgradeInfo.validUntil,
    },
  );

  return data.data;
};

export const downgradePeerSupportToMemberPost = async (organizationId: string, userId: string) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(
    `v1/organization/${organizationId}/users/${userId}/downgrade-to-employee`,
  );

  return data.data;
};

export const peerSupportDashboardGet = async (): Promise<TPeerSupportDashboard> => {
  const { data } = await api.get<TCustomAxiosResponse<TPeerSupportDashboard>>(endpoints.peerSupportDashboard);

  return data.data;
};

// organizationId: string, userId: string, type: TOrganizationEventType, data:
export const createOutreachEventPost = async (eventInfo: TPeerSupportOutreachCreation) => {
  const { data } = await api.post<TCustomAxiosResponse<unknown>>(endpoints.peerSupportOutreach, eventInfo);

  return data.data;
};

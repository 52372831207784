import ConfirmationModal from '@/components/molecules/ConfirmationModal';

interface OrganizationRosterDeleteItemModalProps {
  downgradeConfirmationModalVisible: boolean;
  setDowngradeConfirmationModalVisible: (modalVisible: boolean) => void;
  downgradeItem: (itemId: string) => void;
  itemToDowngradeId: string;
  itemToDowngradeName: string;
  isDowngradePending: boolean;
}

const OrganizationRosterDowngradePeerSupportModal = ({
  downgradeConfirmationModalVisible,
  setDowngradeConfirmationModalVisible,
  downgradeItem,
  itemToDowngradeId,
  itemToDowngradeName,
  isDowngradePending,
}: OrganizationRosterDeleteItemModalProps) => {
  return (
    <ConfirmationModal
      isOpen={downgradeConfirmationModalVisible}
      onClose={() => setDowngradeConfirmationModalVisible(false)}
      confirmButtonColor="extra.red"
      confirmButtonText="Downgrade"
      onConfirm={() => {
        downgradeItem(itemToDowngradeId);
        setDowngradeConfirmationModalVisible(false);
      }}
      title="Confirm Downgrade"
      confirmLoading={isDowngradePending}
      description={`Are you sure you want to downgrade ${itemToDowngradeName}\n to Employee?`}
    />
  );
};

export default OrganizationRosterDowngradePeerSupportModal;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import useToast from '../useToast';
import { TCustomAxiosError } from '@/services/api';
import queryKeys from '@/constants/queryKeys';
import { updateNewPeerSupportProfilePut } from '@/services/api/requests/peer-support';
import { TPeerSupportProfileInfo } from '@/types/PeerSupport.types';

const useUpdateNewPeerSupportProfile = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async ({ peerSupportUpdateInfo }: { peerSupportUpdateInfo: TPeerSupportProfileInfo }) => {
      const response = await updateNewPeerSupportProfilePut(peerSupportUpdateInfo);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.auth.user] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Peer Support Bio updated successfully!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

export default useUpdateNewPeerSupportProfile;

import iconsPng from '@/assets/img/png/icons';
import { WELLNESS_DOMAINS_DISPLAY_LABELS } from '@/constants';
import { THealthDomain } from '@/types/Health.types';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';

export const getWellnessDomainLabelFromName = (domain: THealthDomain, size = '14px') => {
  return (
    <HStack justifyContent={'flex-start'} width={'100%'} gap={'10px'}>
      <VStack backgroundColor={'background.lightBlue'} padding={'3px'} borderRadius={'4px'}>
        <Image src={iconsPng.wellnessDomains[domain]} width={size} height={size} />
      </VStack>
      <Text variant={'urbanistSmallBold'} cursor={'default'} color={'text.mediumGray'} fontWeight={800}>
        {WELLNESS_DOMAINS_DISPLAY_LABELS[domain].toUpperCase()} WHOLENESS
      </Text>
    </HStack>
  );
  // switch (domain) {
  //   case 'mental':
  //     return <HStack></HStack>;

  //   case 'physical':
  //     return;

  //   case 'fun':
  //     return;

  //   case 'purpose':
  //     return;

  //   case 'social':
  //     return;

  //   case 'spiritual':
  //     return;

  //   case 'vocational':
  //   default:
  //     return;
  // }
};

import svgIcons from '@/assets/svg/icons';
import { Flex, Icon, Text } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
  isPending: boolean;
}

const OrgMemberPendingReinvite = ({ onClick, isPending }: Props) => {
  return (
    <Flex
      flexDir={'row'}
      alignItems={'center'}
      width={'50%'}
      cursor={isPending ? 'not-allowed' : 'pointer'}
      onClick={isPending ? () => {} : onClick}
      role={isPending ? 'none' : 'group'} // removes group hover animation while the request is pending
    >
      <Text color={'background.blueGray'} _groupHover={{ color: 'text.mediumBlue', fontWeight: 600 }}>
        Pending
      </Text>
      <Icon
        // animation={isPending ? spinAnimation : 'none'}
        color="background.blueGray"
        _groupHover={{ color: 'text.mediumBlue' }}
        marginTop={'3px'}
        marginLeft={'5px'}
      >
        <svg width={'34'} height={'34'} viewBox={`0 0 20 20`} xmlns="http://www.w3.org/2000/svg">
          <path d={svgIcons.reload} fill="currentColor" />
        </svg>
      </Icon>
    </Flex>
  );
};

export default OrgMemberPendingReinvite;

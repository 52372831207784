const rootPath = '/img/png/icons';

const iconsPng = {
  eyeClosed: `${rootPath}/eye-closed.png`,
  eyeOpened: `${rootPath}/eye-opened.png`,
  lock: `${rootPath}/lock.png`,
  info: `${rootPath}/info.png`,
  redX: `${rootPath}/red-x.png`,
  addMember: `${rootPath}/add-member.png`,
  lockPassword: `${rootPath}/lock-password.png`,
  userDeactivate: `${rootPath}/user-deactivate.png`,
  okHand: `${rootPath}/ok-hand.png`,
  notepad: `${rootPath}/notepad.png`,
  pen: `${rootPath}/pen.png`,
  sliderCircleGrey: `${rootPath}/slider-circle-circled.png`,
  sliderCircleUntouched: `${rootPath}/slider-circle-untouched.png`,
  orangeExclamationMark: `${rootPath}/orange-exclamation.png`,
  topRightBackground: `${rootPath}/top-right-background-circle.png`,
  personReading: `${rootPath}/person-reading.png`,
  handshake: `${rootPath}/handshake.png`,
  wellnessDomains: {
    fun: `${rootPath}/wellness-domains/fun.png`,
    vocational: `${rootPath}/wellness-domains/vocational.png`,
    spiritual: `${rootPath}/wellness-domains/spiritual.png`,
    mental: `${rootPath}/wellness-domains/mental.png`,
    physical: `${rootPath}/wellness-domains/physical.png`,
    purpose: `${rootPath}/wellness-domains/purpose.png`,
    social: `${rootPath}/wellness-domains/social.png`,
  },
};

export default iconsPng;

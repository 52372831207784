import SvgIconThreeDots from '@/components/atoms/SvgIconThreeDots';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { BUTTON_BOX_SHADOW_STYLE } from '@/constants/ui';
import colors from '@/theme/colors';
import { TRosterItemWithIdAndRole } from '@/types/Roster.types';
import {
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';

interface Props {
  item: TRosterItemWithIdAndRole;
  onClickDelete: (item: TRosterItemWithIdAndRole) => void;
  onClickPeerSupport: (item: TRosterItemWithIdAndRole) => void;
  onClickReinvite: (item: TRosterItemWithIdAndRole) => void;
}

const OrgMembersRosterDropdown = ({ item, onClickDelete, onClickPeerSupport, onClickReinvite }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isOnboarded = item.Invitation === 'Accepted';

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Popover placement={'bottom'} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <HStack padding={'8px'} maxWidth={'50px'} marginLeft={'-5px'}>
        <PopoverTrigger>
          <Button
            width={'30px'}
            height={'44px'}
            cursor="pointer"
            _hover={{ backgroundColor: 'primary.500', boxShadow: BUTTON_BOX_SHADOW_STYLE }}
            background={isOpen ? 'primary.500' : 'background.lightGray'}
            paddingY={'10px'}
            paddingX={'16px'}
            borderRadius={'8px'}
            boxShadow={isOpen ? BUTTON_BOX_SHADOW_STYLE : 'none'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <SvgIconThreeDots color={isHovered || isOpen ? colors.extra.white : colors.text.darkBlue} />
          </Button>
        </PopoverTrigger>

        {/* whole disabled trigger button style */}
        {/* <PopoverTrigger>
          <Button
            width={'30px'}
            height={'44px'}
            cursor={isOnboarded ? 'pointer' : 'not-allowed'}
            background={isOpen ? 'primary.500' : 'background.lightGray'}
            _hover={{
              backgroundColor: isOnboarded ? 'primary.500' : 'background.lightGray',
              boxShadow: BUTTON_BOX_SHADOW_STYLE,
            }}
            paddingY={'10px'}
            paddingX={'16px'}
            borderRadius={'8px'}
            boxShadow={isOpen ? BUTTON_BOX_SHADOW_STYLE : 'none'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}

          >
            <SvgIconThreeDots color={isHovered || isOpen ? colors.extra.white : colors.text.darkBlue} />
          </Button>
        </PopoverTrigger> */}
      </HStack>
      <PopoverContent
        borderRadius={'8px'}
        borderColor={'transparent'}
        maxWidth={isOnboarded ? '270px' : '200px'}
        boxShadow={BUTTON_BOX_SHADOW_STYLE}
      >
        <VStack gap={'12px'} paddingY={'20px'}>
          <Text
            onClick={() => onClickDelete(item)}
            variant={'urbanistSemiBold'}
            cursor={'pointer'}
            _hover={{ fontWeight: 800 }}
          >
            Remove Member
          </Text>

          {!isOnboarded && (
            <Text
              onClick={() => onClickReinvite(item)}
              variant={'urbanistSemiBold'}
              cursor={'pointer'}
              _hover={{ fontWeight: 800 }}
            >
              Reinvite Member
            </Text>
          )}

          <Tooltip
            label={'This option will be available after onboarding is completed'}
            placement={'right-start'}
            color={'text.mediumBlue'}
            background={'white'}
            maxWidth={FORM_FIELD_WIDTH}
            borderRadius={'8px'}
            fontWeight={600}
            padding={'10px'}
            display={isOnboarded ? 'none' : 'flex'}
            zIndex={20}
          >
            <Text
              onClick={() => (isOnboarded ? onClickPeerSupport(item) : {})}
              variant={'urbanistSemiBold'}
              cursor={isOnboarded ? 'pointer' : 'not-allowed'}
              fontWeight={isOnboarded ? 600 : 400}
              _hover={{ fontWeight: isOnboarded ? 800 : 400 }}
            >
              {item.isPeerSupport ? 'Downgrade from' : 'Upgrade to'} Peer Support
            </Text>
          </Tooltip>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default OrgMembersRosterDropdown;

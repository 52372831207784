import queryKeys from '@/constants/queryKeys';
import { organizationMembersForAdminGet } from '@/services/api/requests/organization';
import { TUser } from '@/types/User.types';
import { Box, Center, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppStore } from '@/store/useAppStore';
import CustomButton from '@/components/atoms/CustomButton';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import Loader from '@/components/atoms/Loader';
import OrganizationRosterDeleteMemberModal from './OrganizationRosterDeleteMemberModal';
import { TRosterItemWithIdAndRole } from '@/types/Roster.types';
import iconsPng from '@/assets/img/png/icons';
import tableTheme from '@/theme/components/table';
import { TABLE_COLUMNS_ORG_ROSTER_VIEW } from '@/utils/tableConfigs';
import roles from '@/constants/roles';
import useRemoveMemberFromOrganization from '@/hooks/organization/removeMemberFromOrganization';
import OrganizationRosterAddMemberModal from './OrganizationRosterAddMemberModal';
import OrgMemberPendingInvite from './OrgMemberPendingReinvite';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import OrgMembersRosterDropdown from './OrgMembersRosterDropdown';
import OrganizationRosterPeerSupportModal from './OrganizationRosterPeerSupportModal';
import OrganizationRosterDowngradePeerSupportModal from './OrganizationRosterDowngradePeerSupportModal';
import useDowngradePeerSupportToMember from '@/hooks/peersupport/downgradePeerSupportToMember';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import useReinviteMemberToOrganization from '@/hooks/organization/reinviteMemberToOrganization';
import { usePaginatedSearch } from '@/hooks/usePaginatedSearch';
import SearchInputBox from '@/components/atoms/SearchInputBox';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants';

const WAIT_LOADING_MILLISECONDS = 700;

const OrgMembersRoster = () => {
  const theme = useTheme({
    ...tableTheme,
    Table: `${tableTheme.Table}; --data-table-library_grid-template-columns: 15% 15% 15% 25% 15% 15%`,
  });

  const queryClient = useQueryClient();

  const { organization } = useAppStore();

  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [isReinviteModalOpened, setIsReinviteModalOpened] = useState(false);
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);
  const [downgradeConfirmationModalVisible, setDowngradeConfirmationModalVisible] = useState(false);

  const [itemToDeleteEmail, setItemToDeleteEmail] = useState('');
  const [itemToDeleteId, setItemToDeleteId] = useState('');

  const onClickDelete = useCallback((item: TRosterItemWithIdAndRole) => {
    setItemToDeleteId(item.id);
    setItemToDeleteEmail(item.Email);
    setDeleteConfirmationModalVisible(true);
  }, []);

  const [peerSupportModalVisible, setPeerSupportModalVisible] = useState(false);
  const [itemToUpgradeToPeerSupportId, setItemToUpgradeToPeerSupportId] = useState('');
  const [itemToUpgradeToPeerSupportName, setItemToUpgradeToPeerSupportName] = useState('second');

  const [itemToDowngradeFromPeerSupportName, setItemToDowngradeFromPeerSupportName] = useState('');
  const [itemToDowngradeFromPeerSupportId, setItemToDowngradeFromPeerSupportId] = useState('');

  const onClickPeerSupport = useCallback((item: TRosterItemWithIdAndRole) => {
    if (item.isPeerSupport) {
      setItemToDowngradeFromPeerSupportId(item.id);
      setItemToDowngradeFromPeerSupportName(`${item['First Name']} ${item['Last Name']}`);
      setDowngradeConfirmationModalVisible(true);
    } else {
      setItemToUpgradeToPeerSupportId(item.id);
      setItemToUpgradeToPeerSupportName(`${item['First Name']} ${item['Last Name']}`);
      setPeerSupportModalVisible(true);
    }
  }, []);

  const tableColumns = useMemo(() => {
    const columns: {
      label: string | JSX.Element;
      renderCell: (item: TRosterItemWithIdAndRole) => string | JSX.Element;
    }[] = TABLE_COLUMNS_ORG_ROSTER_VIEW;

    if (columns.length === 5) {
      columns.push({
        label: <Flex maxWidth={'100px'}>DELETE</Flex>,
        renderCell: () => '',
      });
    }
    return columns;
  }, [onClickDelete]);

  // const {
  //   isLoading: isOrgMembersLoading,
  //   data: orgMembers,
  //   refetch: refetchOrgMembers,
  //   isRefetching: isFetchingOrgMembers,
  //   pagination,
  // } = usePagination<TUser[]>({
  //   query: organizationMembersForAdminGet,
  //   queryKey: queryKeys.organization.members,
  // });

  const [filterText, setFilterText] = useState('');

  const {
    isLoading: isOrgMembersLoading,
    data: orgMembers,
    refetch: refetchOrgMembers,
    isRefetching: isFetchingOrgMembers,
    pagination,
  } = usePaginatedSearch<TUser[], string, string>({
    query: organizationMembersForAdminGet,
    queryKey: queryKeys.organization.members,
    searchFilterType: '',
    searchFilterText: filterText.length >= MIN_CHARS_TO_DO_SEARCH ? filterText : undefined,
  });

  const [isWaitLoading, setIsWaitLoading] = useState(false); // improves UX by having the UI start the loader immediately after an action (add/delete member); this is needed because we have to wait 2 seconds to fetch data from auth0

  const isLoading = isOrgMembersLoading || isWaitLoading;

  const [userNameToReinvite, setUserNameToReinvite] = useState('');
  const [userIdToReinvite, setUserIdToReinvite] = useState('');

  const { mutate: reinviteMemberToOrganization, isPending: isMemberReinvitePending } =
    useReinviteMemberToOrganization();

  const mapBeUserToTableUser = useCallback(
    (users: TUser[]): TRosterItemWithIdAndRole[] =>
      users.map((user) => ({
        'First Name': user.firstName ?? user.user_metadata.firstName,
        'Last Name': user.lastName ?? user.user_metadata.lastName,
        Phone: user.user_metadata.phone ?? '/',
        Email: user.email,
        Invitation:
          user.email_verified ||
          user.roles?.includes(roles.orgAdmin) ||
          user.app_metadata.password_setup_complete ||
          !user.user_metadata.requires_password_change ? (
            'Accepted'
          ) : (
            <OrgMemberPendingInvite
              isPending={isMemberReinvitePending}
              onClick={() => {
                setUserNameToReinvite(
                  `${user?.firstName ?? user?.user_metadata.firstName} ${user?.lastName ?? user?.user_metadata.lastName}`,
                );
                setUserIdToReinvite(user.user_id);
                setIsReinviteModalOpened(true);
              }}
            />
          ),
        id: user.user_id,
        isAdmin: user.roles?.includes(roles.orgAdmin),
        isPeerSupport: user.roles?.includes(roles.peerSupport),
      })),
    [isMemberReinvitePending],
  );

  const [rawData, setRawData] = useState([] as TRosterItemWithIdAndRole[]);

  useEffect(() => {
    if (orgMembers) {
      setRawData(mapBeUserToTableUser(orgMembers));
    }
  }, [orgMembers, mapBeUserToTableUser]);

  const data = useMemo(() => ({ nodes: rawData ?? [] }), [rawData]);

  const reFetch = useCallback(() => {
    // TODO: see how to improve this... remove the wishful 2s wait and waitLoading...
    setTimeout(() => setIsWaitLoading(true), WAIT_LOADING_MILLISECONDS);
    setTimeout(() => {
      pagination.getFirstPage();
      queryClient.invalidateQueries({ queryKey: [queryKeys.organization.members], exact: false });
      setIsWaitLoading(false);
    }, 2000); // if we don't wait 2 seconds before re-fetching, newly added user doesn't appear
  }, [refetchOrgMembers]);

  const { mutate: removeOrgMember } = useRemoveMemberFromOrganization(reFetch);

  const deleteMember = useCallback(
    (memberId: string) => {
      setTimeout(() => setIsWaitLoading(true), WAIT_LOADING_MILLISECONDS);
      removeOrgMember(memberId);
    },
    [removeOrgMember],
  );

  const { mutate: downgradePeerSupportToMemberMutate, isPending: isDowngradePending } =
    useDowngradePeerSupportToMember();

  const downgradePeerSupportToMember = useCallback(() => {
    downgradePeerSupportToMemberMutate({
      organizationId: organization?._id ?? '',
      userId: itemToDowngradeFromPeerSupportId,
    });
    setDowngradeConfirmationModalVisible(false);
  }, [organization, itemToDowngradeFromPeerSupportId]);

  return (
    <Center marginTop={'30px'} paddingBottom={'10vh'}>
      <VStack width={MAX_CONTAINER_WIDTH} gap={0}>
        <HStack width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Text variant={'loraTitle'}>{organization?.name}&apos;s Roster</Text>

          <HStack gap={'30px'}>
            <SearchInputBox setFilterText={setFilterText} isSearching={isFetchingOrgMembers} />
            <CustomButton
              width={'150px'}
              height={'55px'}
              onClick={() => setAddMemberModalVisible(true)}
              backgroundColor="primary.500"
              labelColor="extra.white"
              label="Add Member"
            />
          </HStack>
        </HStack>

        {isLoading ? (
          <Center height={'860px'} paddingBottom={'20vh'}>
            <Loader />
          </Center>
        ) : (
          <VStack width={'100%'}>
            <Box marginTop={'60px'} width={'100%'}>
              {data.nodes.length > 0 ? (
                <>
                  <Table data={data} theme={theme} layout={{ custom: true }}>
                    {(tableDataItems: TRosterItemWithIdAndRole[]) => (
                      <>
                        <Header>
                          <HeaderRow>
                            {tableColumns.map((column) => (
                              <HeaderCell key={column.label.toString()}>{column.label}</HeaderCell>
                            ))}
                          </HeaderRow>
                        </Header>

                        <Body>
                          {tableDataItems.map((item) => {
                            return (
                              <Row key={item.id} item={item}>
                                <Cell>{item['First Name']}</Cell>
                                <Cell>{item['Last Name']}</Cell>
                                <Cell>{item.Phone}</Cell>
                                <Cell>{item.Email}</Cell>
                                <Cell>{item.Invitation}</Cell>
                                {/* Add in "Admin" text for admins or dropdown button for regular users  */}
                                <Cell>
                                  {item.isAdmin ? (
                                    <Text variant={'urbanistBold'}>Admin</Text>
                                  ) : (
                                    <OrgMembersRosterDropdown
                                      item={item}
                                      onClickDelete={onClickDelete}
                                      onClickPeerSupport={onClickPeerSupport}
                                      onClickReinvite={() => {
                                        setUserNameToReinvite(`${item['First Name']} ${item?.['Last Name']}`);
                                        setUserIdToReinvite(item.id);
                                        setIsReinviteModalOpened(true);
                                      }}
                                    />
                                  )}
                                </Cell>
                              </Row>
                            );
                          })}
                        </Body>
                      </>
                    )}
                  </Table>
                  <CompactTablePagination pagination={pagination} isFetchingNextPage={isFetchingOrgMembers} />
                </>
              ) : (
                <VStack
                  paddingY={'60px'}
                  justifyContent={'center'}
                  backgroundColor={'extra.white'}
                  borderRadius={'10px'}
                  width={MAX_CONTAINER_WIDTH}
                >
                  <Image alt="notepad" src={iconsPng.notepad} width={120} height={120} />
                  <Text variant={'urbanistSemiBoldSmallTitle'} color={'text.mediumGray'} marginTop={'30px'}>
                    Start by inviting/adding members to roster.
                  </Text>
                </VStack>
              )}
            </Box>
          </VStack>
        )}

        {/* Add Member modal */}
        <OrganizationRosterAddMemberModal
          addMemberModalVisible={addMemberModalVisible}
          setAddMemberModalVisible={setAddMemberModalVisible}
          reFetch={reFetch}
        />

        {/* Reinvite Member modal */}
        <ConfirmationModal
          title="Confirm Reinvite"
          description={`Send another invite to ${userNameToReinvite}?`}
          confirmButtonText="Reinvite"
          isOpen={isReinviteModalOpened}
          onClose={() => setIsReinviteModalOpened(false)}
          onConfirm={() => {
            reinviteMemberToOrganization(userIdToReinvite);
            setIsReinviteModalOpened(false);
          }}
        />

        <OrganizationRosterDeleteMemberModal
          deleteConfirmationModalVisible={deleteConfirmationModalVisible}
          setDeleteConfirmationModalVisible={setDeleteConfirmationModalVisible}
          deleteItem={deleteMember}
          itemToDeleteId={itemToDeleteId}
          itemToDeleteEmail={itemToDeleteEmail}
        />

        <OrganizationRosterPeerSupportModal
          peerSupportModalVisible={peerSupportModalVisible}
          setPeerSupportModalVisible={setPeerSupportModalVisible}
          reFetch={reFetch}
          organizationId={organization?._id ?? ''}
          userToUpgradeToPeerSupportId={itemToUpgradeToPeerSupportId}
          itemToUpgradeToPeerSupportName={itemToUpgradeToPeerSupportName}
        />

        <OrganizationRosterDowngradePeerSupportModal
          downgradeConfirmationModalVisible={downgradeConfirmationModalVisible}
          setDowngradeConfirmationModalVisible={setDowngradeConfirmationModalVisible}
          downgradeItem={downgradePeerSupportToMember}
          itemToDowngradeName={itemToDowngradeFromPeerSupportName}
          itemToDowngradeId={itemToDowngradeFromPeerSupportId}
          isDowngradePending={isDowngradePending}
        />
      </VStack>
    </Center>
  );
};

export default OrgMembersRoster;

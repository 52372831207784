import { Button, HStack, Popover, PopoverContent, PopoverTrigger, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import { capitalize } from '@/utils/stringUtils';
import SvgIcon from '../SvgIcon';
import svgIcons from '@/assets/svg/icons';
import { useMemo } from 'react';

interface Props<TOption> {
  title: string;
  options: readonly TOption[];
  selectedOptions?: TOption[];
  onOptionClick: (option: TOption) => void;
  optionLabelDisplay?: (_: string) => string;
  height?: string;
  width?: number;
  popoverWidth?: number;
}

const Dropdown = <TOption,>({
  title,
  options,
  selectedOptions,
  onOptionClick,
  optionLabelDisplay,
  height = '54px',
  width = 300,
  popoverWidth = width,
}: Props<TOption>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const noValueSelected = !selectedOptions || selectedOptions.length === 0;

  const selectedOptionsLabel = useMemo(() => {
    if (noValueSelected) return '';

    const label = selectedOptions.length
      ? selectedOptions.map((selectedOption, i) => {
          return `${optionLabelDisplay ? optionLabelDisplay(selectedOption as string) : capitalize(selectedOption as string)}${i < selectedOptions.length - 1 ? ', ' : ''}`;
        })
      : '';

    return label;
  }, [noValueSelected, title, selectedOptions]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
      <PopoverTrigger>
        <Button
          minHeight={'50px'}
          height={height}
          width={width}
          bg={isOpen ? colors.background.lightBlue : 'transparent'}
          cursor={'pointer'}
          padding={'10px'}
          border={`1px solid ${noValueSelected ? '#E2E8F0' : '#004179'}`}
          boxShadow={`0px 2px ${noValueSelected ? '4px 0px #00417911' : '8px 0px #00417933'}`}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
        >
          <HStack width={'100%'}>
            <VStack flexGrow={1} alignItems={'flex-start'}>
              {noValueSelected ? (
                <Text
                  fontSize={'14px'}
                  color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                  lineHeight={'20px'}
                  fontWeight={700}
                  maxWidth={`${width ? width - 50 : 100}px`}
                  isTruncated
                >
                  {title}
                </Text>
              ) : (
                <VStack alignItems={'flex-start'} gap={'2px'} width={'100%'}>
                  <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                    {title.split(' ')[0].toUpperCase()}
                  </Text>
                  <Text variant={'urbanistSemiBoldRegular'} maxWidth={`${width ? width - 50 : 100}px`} isTruncated>
                    {selectedOptionsLabel}
                  </Text>
                </VStack>
              )}
            </VStack>
            {isOpen ? (
              <ChevronUpIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            ) : (
              <ChevronDownIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={`${popoverWidth}px`}
        border={'1px solid #95B1C9'}
        padding={'10px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {options.map((option) => (
          <HStack
            key={option as string}
            cursor={'pointer'}
            justifyContent={'space-between'}
            paddingX={'10px'}
            paddingY={'5px'}
            onClick={() => onOptionClick(option)}
            data-group
          >
            <Text
              _groupHover={{ fontWeight: 700 }}
              fontWeight={selectedOptions?.includes(option) ? 700 : 600}
              color={'text.mediumBlue'}
              isTruncated
            >
              {optionLabelDisplay ? optionLabelDisplay(option as string) : capitalize(option as string)}
            </Text>
            {selectedOptions?.includes(option) && (
              <SvgIcon color={colors.text.mediumGray} iconPath={svgIcons.checkWhite} size={'12'} />
            )}
          </HStack>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default Dropdown;

import {
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import svgIcons from '@/assets/svg/icons';
import SvgIcon from '../SvgIcon';
import { useMemo } from 'react';

type TSection<T extends string> = Record<T, string[]>;

interface Props<TSectionName extends string> {
  title: string;
  sections: TSection<TSectionName>;
  selectedSectionsOptions?: Partial<TSection<TSectionName>>;
  sectionLabelDisplay?: (_: TSectionName) => JSX.Element;
  sectionOptionLabelDisplay?: (_: string) => string;
  onSectionOptionClick: (section: string, selectedSectionOption: string) => void;
  height?: string;
  popoverMaxHeight?: number;
  width?: number;
  popoverWidth?: number;
  noOptionsTooltip?: string;
}

const DropdownWithSections = <TSectionName extends string>({
  title,
  sections,
  selectedSectionsOptions,
  onSectionOptionClick,
  sectionLabelDisplay,
  sectionOptionLabelDisplay,
  height = '54px',
  popoverMaxHeight = 300,
  width = 300,
  popoverWidth = width,
  noOptionsTooltip = 'No options available',
}: Props<TSectionName>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasNoOptions = Object.values(sections).every((options) => (options as string[]).length === 0);

  const selectedSectionsOptionsWithData = selectedSectionsOptions
    ? Object.fromEntries(
        Object.entries(selectedSectionsOptions).filter(([key]) => selectedSectionsOptions[key as TSectionName]?.length),
      )
    : undefined;

  const noValueSelected =
    !selectedSectionsOptionsWithData ||
    Object.entries(selectedSectionsOptionsWithData).every(
      ([_, sectionItems]) => (sectionItems as string[]).length === 0,
    );

  const notAllSectionsSelected =
    selectedSectionsOptionsWithData &&
    Object.entries(selectedSectionsOptionsWithData).length !== Object.entries(sections).length;

  const selectedOptionsLabel = useMemo(() => {
    if (selectedSectionsOptionsWithData) {
      return Object.values(selectedSectionsOptionsWithData)
        .map((values) =>
          (values as string[]).map((value) => sectionOptionLabelDisplay?.(value as TSectionName)).join(', '),
        )
        .join(', ');
    }
  }, [selectedSectionsOptionsWithData]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
      <PopoverTrigger>
        <Button
          minHeight={'40px'}
          height={height}
          width={width}
          bg={isOpen ? colors.background.lightBlue : 'transparent'}
          cursor={'pointer'}
          padding={0}
          border={`1px solid ${noValueSelected || notAllSectionsSelected ? '#E2E8F0' : '#004179'}`}
          boxShadow={`0px 2px ${noValueSelected || notAllSectionsSelected ? '4px 0px #00417911' : '8px 0px #00417933'}`}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
          isDisabled={hasNoOptions}
        >
          <Tooltip
            label={noOptionsTooltip}
            visibility={hasNoOptions ? 'visible' : 'hidden'}
            padding={'10px'}
            borderRadius={'6px'}
            color={'text.darkBlue'}
            backgroundColor={'extra.white'}
            boxShadow={'0px 2px 8px 0px #00417933'}
          >
            <HStack height={'100%'} width={'100%'} padding={'10px'}>
              <VStack flexGrow={1} alignItems={'flex-start'}>
                {noValueSelected ? (
                  <Text
                    fontSize={'14px'}
                    color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                    lineHeight={'20px'}
                    fontWeight={700}
                    maxWidth={`${width ? width - 50 : 100}px`}
                    isTruncated
                  >
                    {title}
                  </Text>
                ) : (
                  <VStack alignItems={'flex-start'} gap={'2px'}>
                    <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
                      {title.toUpperCase()}
                    </Text>
                    <Text variant={'urbanistSemiBoldRegular'} maxWidth={`${width ? width - 70 : 100}px`} isTruncated>
                      {selectedOptionsLabel}
                    </Text>
                  </VStack>
                )}
              </VStack>
              {isOpen ? (
                <ChevronUpIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
              ) : (
                <ChevronDownIcon
                  boxSize={'5'}
                  color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue}
                />
              )}
            </HStack>
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={`${popoverWidth}px`}
        maxHeight={`${popoverMaxHeight}px`}
        overflowY={'scroll'}
        border={'1px solid #95B1C9'}
        padding={'20px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {Object.entries(sections).map(([sectionName, subdomains], i) => (
          <VStack marginTop={i > 0 ? '20px' : 0} key={sectionName}>
            {sectionLabelDisplay ? sectionLabelDisplay(sectionName as TSectionName) : sectionName}

            {(subdomains as string[]).map((subdomain) => (
              <HStack
                key={subdomain as string}
                cursor={'pointer'}
                justifyContent={'space-between'}
                paddingX={'10px'}
                paddingY={'5px'}
                onClick={() => onSectionOptionClick(sectionName, subdomain)}
                width={'100%'}
                data-group
              >
                <Text
                  _groupHover={{ fontWeight: 700 }}
                  fontWeight={selectedSectionsOptions?.[sectionName as TSectionName]?.includes(subdomain) ? 700 : 600}
                  noOfLines={1}
                >
                  {sectionOptionLabelDisplay ? sectionOptionLabelDisplay(subdomain) : subdomain}
                </Text>
                {selectedSectionsOptions?.[sectionName as TSectionName]?.includes(subdomain) && (
                  <SvgIcon color={colors.text.mediumGray} iconPath={svgIcons.checkWhite} size={'12'} />
                )}
              </HStack>
            ))}
          </VStack>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default DropdownWithSections;

import type { CSSProperties } from 'react';

const SvgIconThreeDots = ({
  color,
  size = 16,
  style,
}: {
  color: string;
  size?: number;
  style?: CSSProperties;
}) => {
  return (
    <div style={style}>
      <svg
        width={size / 4}
        height={size}
        viewBox={`0 0 ${size / 4} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={size / 8} cy={size / 8} r={size / 8} fill={color} />
        <circle cx={size / 8} cy={size / 2} r={size / 8} fill={color} />
        <circle cx={size / 8} cy={size - size / 8} r={size / 8} fill={color} />
      </svg>
    </div>
  );
};

export default SvgIconThreeDots;

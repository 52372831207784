import { FileRoutesByTo } from '../../routeTree.gen.ts';

type TRoutes = keyof FileRoutesByTo;

export const employeeUserRoutes: Record<string, TRoutes> = {
  userDashboard: '/user/dashboard',
  userProfile: '/user/profile',
  userProfileDemographics: '/user/profile/demographics',
  userProfileContact: '/user/profile/contact',
  // screener: '/screener',
  // assessment: '/assessment',
  onboardingUser: '/onboarding/user',
  onboardingWholenessScreener: '/onboarding/wholeness-screener',
};

export const peerSupportRoutes: Record<string, TRoutes> = {
  peerSupportDashboard: '/peer-support/dashboard',
  ...employeeUserRoutes,
};

export const orgAdminRoutes: Record<string, TRoutes> = {
  ...employeeUserRoutes,
  organizationDashboard: '/organization/dashboard',
  organizationRoster: '/organization/roster',
  onboardingOrganization: '/onboarding/organization',
};

const appRoutes = {
  ...orgAdminRoutes,
};

export default appRoutes;

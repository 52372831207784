import { orgAdminRoutes, employeeUserRoutes, peerSupportRoutes } from '@/constants/routes/app';
import sharedRoutes from '@/constants/routes/shared';
import menuMessages from '@/messages/menu';

export type TMenuOption = {
  label: string;
  navigateTo: string;
};

export const employeeRoleMenuOptions: TMenuOption[] = [
  {
    label: menuMessages.myProfile,
    navigateTo: employeeUserRoutes.userProfile,
  },
  {
    label: menuMessages.myDash,
    navigateTo: employeeUserRoutes.userDashboard,
  },
  {
    label: menuMessages.contactSupport,
    navigateTo: sharedRoutes.contactSupport,
  },
  {
    label: menuMessages.settings,
    navigateTo: sharedRoutes.settings,
  },
];

export const orgAdminRoleMenuOptions: TMenuOption[] = [
  {
    label: menuMessages.organizationDash,
    navigateTo: orgAdminRoutes.organizationDashboard,
  },
  ...employeeRoleMenuOptions,
];

export const peerSupportRoleMenuOptions: TMenuOption[] = [
  { label: menuMessages.peerSupportDashboard, navigateTo: peerSupportRoutes.peerSupportDashboard },
  ...employeeRoleMenuOptions,
];

import iconsPng from '@/assets/img/png/icons';
import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
// import { peerSupportRoutes } from '@/constants/routes/app';
import usePeerSupportDashboard from '@/hooks/peersupport/usePeerSupportDashboard';
import PeerSupportNewOutreachModal from '@/modules/peer-support/PeerSupportNewOutreachModal';
import colors from '@/theme/colors';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { format } from 'date-fns';
import { useState } from 'react';

const ORG_LOGO_SIZE = 140;

// const CHART_COLORS_ARRAY = [
//   colors.extra.teal,
//   colors.extra.violet,
//   colors.extra.yellow,
//   colors.extra.orange,
//   colors.extra.red,
// ];

const PeerSupportDashboard = () => {
  // const navigate = useNavigate({ from: peerSupportRoutes.peerSupportDashboard });

  const { data: peerSupportDashboard, isLoading } = usePeerSupportDashboard();
  console.log('peerSupportDashboard', peerSupportDashboard);

  const [outreachModalOpen, setOutreachModalOpen] = useState(false);

  if (isLoading) return <LoadingPage />;

  return (
    <VStack width={MAX_CONTAINER_WIDTH} position={'relative'}>
      {/* Org Logo */}
      <Center position="absolute" top={`-${ORG_LOGO_SIZE / 2}px`}>
        <VStack backgroundColor={'white'} borderRadius={'50%'} boxShadow="0px 10px 20px 0px #0E006224">
          <Image
            alt="Peer Support Dash"
            width={`${ORG_LOGO_SIZE}px`}
            height={`${ORG_LOGO_SIZE}px`}
            src={peerSupportDashboard?.logoUrl ?? iconsPng.handshake}
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
        </VStack>
      </Center>

      {/* Actual page content */}
      <VStack
        width={'100%'}
        borderRadius={'20px'}
        backgroundColor={'extra.white'}
        padding={'40px'}
        paddingTop={`${(ORG_LOGO_SIZE * 2) / 3}px`}
      >
        <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
          <VStack alignItems={'flex-start'}>
            <Text variant="loraTitle" textAlign={'start'}>
              {peerSupportDashboard?.organizationName}'s Peer Support
            </Text>
            <Text variant="urbanistExtraBold" textAlign={'start'} color={'text.mediumGray'}>
              Last 6 months
            </Text>
          </VStack>

          <CustomButton
            backgroundColor="primary.500"
            labelColor="extra.white"
            label="New Outreach"
            iconBeforeLabelJSX={
              <HStack width={'100%'} position={'absolute'} right={'20px'} justifyContent={'flex-end'}>
                <SvgIcon iconPath={svgIcons.rightArrow} color={colors.extra.white} size={'14'} />
              </HStack>
            }
            labelStyle={{ marginRight: '22px' }}
            width={'170px'}
            onClick={() => setOutreachModalOpen(true)}
          />
        </HStack>

        <VStack>
          <Text>Events list (test)</Text>
          {peerSupportDashboard?.events.map((event, i) => (
            <HStack key={i}>
              <Text>type: {event.type} |</Text>
              <Text>start date: {format(event.data.startDate, 'dd-MMM-yyyy')}</Text>
              {event.data.subject.map((subj) => (
                <Text>{subj.domain}, </Text>
              ))}
            </HStack>
          ))}
        </VStack>
      </VStack>

      <PeerSupportNewOutreachModal isOpen={outreachModalOpen} setIsOpen={setOutreachModalOpen} />
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_peer-support/peer-support/dashboard')({
  component: PeerSupportDashboard,
});

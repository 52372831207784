import iconsPng from '@/assets/img/png/icons';
import ModalContainer from '@/components/molecules/ModalContainer';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { Center, Image, Input, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MIN_CHARS_IN_BIO } from '@/constants';
import CustomButton from '@/components/atoms/CustomButton';
import useUpdateNewPeerSupportProfile from '@/hooks/peersupport/updateNewPeerSupportProfile';
import { TUserMetadata } from '@/types/User.types';
import { TPeerSupportProfileInfo } from '@/types/PeerSupport.types';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (_: boolean) => void;
  userMetadata: TUserMetadata;
}

const peerSupportBioSchema = yup
  .object()
  .shape({
    bio: yup
      .string()
      .required('Bio is required.')
      .min(MIN_CHARS_IN_BIO, `Minimum ${MIN_CHARS_IN_BIO} characters required in Bio`),
  })
  .required();

const UserPeerSupportBioModal = ({ isModalOpen, setIsModalOpen, userMetadata }: Props) => {
  const isFirstBioUpdate = !userMetadata.bio;

  const {
    register,
    getValues,
    reset: resetBioForm,
    formState,
    watch,
  } = useForm<{ bio: string }>({
    resolver: yupResolver(peerSupportBioSchema),
    mode: 'all',
    defaultValues: {
      bio: userMetadata.bio,
    },
  });

  const currentBioValue = watch('bio');

  const { isValid, errors } = formState;

  const { mutate: updateNewPeerSupportBioMutation, isPending } = useUpdateNewPeerSupportProfile(() => {
    resetBioForm();
    setIsModalOpen(false);
  });

  const updatePeerSupportBio = () => {
    const vals = getValues();
    /* PS profile object 
     * 
     * {
        "firstName": "CIro",
        "lastName": "Vevato",
        "phone": "+123432123",
        "invitation_sent": true,
        "requires_password_change": false,
        "dateOfBirth": "1990-12-31",
        "education": "Trade/technical/vocational training",
        "ethnicity": "Native Hawaiian or Other Pacific Islands",
        "heightFeet": 5,
        "heightInches": 5,
        "learningStylePreference": [
            "Watch videos"
        ],
        "occupation": "First Responder",
        "relationshipStatus": "Married",
        "weight": 55,
        "profilePicture": "https://omnia-image-storage-dev.s3.us-west-2.amazonaws.com/users/auth0%7C676b0cf79a133017b5976bf1/profile/1735126447225-13-10-2023.png",
        "bio": "asd asd asd asd asd asd ads asd",
        "calendly_link": "https://calendly.com/ciroo"
    }
     */
    const peerSupportUpdateInfo: TPeerSupportProfileInfo = {
      bio: vals.bio,
      firstName: userMetadata.firstName,
      lastName: userMetadata.lastName,
      phone: userMetadata.phone,
      calendly_link: userMetadata.calendly_link,
      dateOfBirth: userMetadata.dateOfBirth,
      profilePicture: userMetadata.profilePicture,
    };
    updateNewPeerSupportBioMutation({ peerSupportUpdateInfo });
  };

  return (
    <ModalContainer
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isBlur={isFirstBioUpdate}
      width={'40%'}
      height={'20vh'}
      backgroundColor={'background.lightBlue'}
      closeOnOverlayClick={!isFirstBioUpdate}
      closeButtonHidden={isFirstBioUpdate}
    >
      <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'20px'} whiteSpace={'pre-wrap'}>
        {isFirstBioUpdate ? 'Set up your Peer Support Profile' : 'Update Your Bio'}
      </Text>
      {isFirstBioUpdate && (
        <>
          <Center marginTop={'20px'}>
            <Image alt="add-member" src={iconsPng.handshake} width={120} height={120} />
          </Center>
          <VStack marginTop={'40px'}>
            <Text variant={'urbanistSemiBoldLarge'} color={'text.mediumBlue'} maxWidth={'300px'}>
              Congrats on your heroic endeavour! Your account has been upgraded to Peer Support. Add a short bio that
              your team will see.
            </Text>
          </VStack>
        </>
      )}
      <Center>
        <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={'20px'}>
          <Input
            {...register('bio', { required: true })}
            as={'textarea'}
            className="custom-input"
            placeholder={'Bio'}
            _placeholder={{
              fontWeight: '600',
              color: 'background.blueGray',
              fontSize: '14px',
            }}
            minHeight={'150px'}
            maxHeight={'350px'}
          />

          {errors.bio && <Text variant={'error'}>{errors.bio.message}</Text>}
          <CustomButton
            style={{ marginTop: '20px' }}
            backgroundColor="secondary.500"
            label={isFirstBioUpdate ? 'Confirm' : 'Update'}
            width={FORM_FIELD_WIDTH}
            labelColor="white"
            onClick={updatePeerSupportBio}
            disabled={!isValid || currentBioValue === userMetadata.bio}
            isLoading={isPending}
          />
        </VStack>
      </Center>
    </ModalContainer>
  );
};

export default UserPeerSupportBioModal;

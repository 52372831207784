const menuMessages = {
  organizationDash: 'Organization Dashboard',
  peerSupportDashboard: 'Peer Support Dashboard',
  myProfile: 'My Profile',
  myDash: 'My Dashboard',
  contactSupport: 'Contact Support',
  settings: 'Settings',
  signOut: 'Sign Out'
}

export default menuMessages;
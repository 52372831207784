import { useMutation, useQueryClient } from '@tanstack/react-query';
import useToast from '../useToast';
import { TCustomAxiosError } from '@/services/api';
import queryKeys from '@/constants/queryKeys';
import { TPeerSupportOutreachCreation } from '@/types/PeerSupport.types';
import { createOutreachEventPost } from '@/services/api/requests/peer-support';

const useCreateOutreachEvent = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (eventInfo: TPeerSupportOutreachCreation) => {
      const response = await createOutreachEventPost(eventInfo);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.peerSupport.dashboard] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Outreach creation successful!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

export default useCreateOutreachEvent;
